import React, { useContext } from 'react';
import { absoluteStyle, flexHorizontalStyle } from '../../styles';
import { PlanColumn } from './PlanColumn';
import { LayoutContext } from '../../layout';

const PlanSheet = () => {
  const layoutMode = useContext(LayoutContext);

  return (
    <div
      style={{
        ...absoluteStyle,
        flexDirection: 'column' as 'column',
        top: 60,
        background: '#EEE',
        overflowX: 'scroll' as 'scroll',
        ...(layoutMode === 'mobile' && { overflowY: 'scroll' as 'scroll' }),
      }}
    >
      <div style={{ ...flexHorizontalStyle }}>
        <PlanColumn weekDay={1} title="PIRMDIENA" />
        <PlanColumn weekDay={2} title="OTRDIENA" />
        <PlanColumn weekDay={3} title="TREŠDIENA" />
        <PlanColumn weekDay={4} title="CETURTDIENA" />
        <PlanColumn weekDay={5} title="PIEKTDIENA" />
        <PlanColumn weekDay={6} title="SESTDIENA" />
        <PlanColumn weekDay={7} title="SVĒTDIENA" />
      </div>
    </div>
  );
};

export { PlanSheet };
