import React from 'react';
import { Popup } from './Popup';
import { EditPlanItem } from '../EditPlanItem/EditPlanItem';
import { SharePlan } from '../SharePlan/SharePlan';
import { NewPlan } from '../NewPlan/NewPlan';
import { BetaInfo } from '../BetaInfo/BetaInfo';
import { PrivacyInfo } from '../PrivacyInfo/PrivacyInfo';
import { MoreInfo } from '../MoreInfo/MoreInfo';

const Popups = () => {
  return (
    <>
      <Popup name="editPlanItem">
        <EditPlanItem />
      </Popup>
      <Popup name="sharePlan">
        <SharePlan />
      </Popup>
      <Popup name="newPlan">
        <NewPlan />
      </Popup>
      <Popup name="betaInfo">
        <BetaInfo />
      </Popup>
      <Popup name="privacyInfo">
        <PrivacyInfo />
      </Popup>
      <Popup name="moreInfo">
        <MoreInfo />
      </Popup>
    </>
  );
};

export { Popups };
