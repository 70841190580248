import React from 'react';

const FormInput = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
  const inputStyle = {
    display: 'block',
    width: '100%',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 16,
    lineHeight: 1.5,
    outline: 0,
    border: '1px solid #DDD',
    borderRadius: 4,
    color: '#666',
    transition: '0.3s',
    ...props.style,
  };

  const propsCleaned = { ...props };
  if (propsCleaned.style) {
    delete propsCleaned.style;
  }

  return (
    <input
      type="text"
      style={inputStyle}
      spellCheck={false}
      {...propsCleaned}
    />
  );
};

export { FormInput };
