import React from 'react';

const FormTextArea = (
  props: React.TextareaHTMLAttributes<HTMLTextAreaElement>,
) => {
  const inputStyle = {
    display: 'block',
    width: '100%',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1.5,
    fontSize: 16,
    borderRadius: 4,
    border: '1px solid #DDD',
    color: '#666',
    transition: '0.3s',
    outline: 0,
    ...props.style,
  };

  const propsCleaned = { ...props };
  if (propsCleaned.style) {
    delete propsCleaned.style;
  }

  return <textarea style={inputStyle} spellCheck={false} {...propsCleaned} />;
};

export { FormTextArea };
