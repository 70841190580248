import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ReactGA from 'react-ga';

type IUIStore = {
  currentlyDraggedPlanItem: ICurrentlyDraggedPlanItem;
  showShoppingCart: boolean;
  showHistory: boolean;
  showNotes: boolean;
  showPanel: IPanelTypes;
};

type IPanelTypes = 'history' | 'shoppingCart' | 'notes' | 'menuPanel' | null;

type ICurrentlyDraggedPlanItem = {
  index: number;
  itemId: number;
  weekDay: number;
} | null;

const initialState: IUIStore = {
  currentlyDraggedPlanItem: null,
  showShoppingCart: false,
  showHistory: false,
  showNotes: false,
  showPanel: null,
};

const UIStore = createSlice({
  name: 'UIStore',
  initialState,
  reducers: {
    updateCurrentlyDraggedBoardItem(
      state,
      action: PayloadAction<ICurrentlyDraggedPlanItem>,
    ) {
      state.currentlyDraggedPlanItem = action.payload;
    },

    uiShowPanel(state, action: PayloadAction<IPanelTypes>) {
      state.showPanel = action.payload;
    },

    uiUpdateShoppingCartShowStatus(state, action: PayloadAction<boolean>) {
      state.showShoppingCart = action.payload;
      state.showHistory = false;
      state.showNotes = false;

      ReactGA.event({
        category: 'ShoppingCart',
        action: 'uiUpdateShoppingCartShowStatus',
      });
    },

    uiUpdateHistoryShowStatus(state, action: PayloadAction<boolean>) {
      state.showHistory = action.payload;
      state.showShoppingCart = false;
      state.showNotes = false;

      ReactGA.event({
        category: 'History',
        action: 'uiUpdateHistoryShowStatus',
      });
    },

    uiUpdateNotesShowStatus(state, action: PayloadAction<boolean>) {
      state.showNotes = action.payload;
      state.showHistory = false;
      state.showShoppingCart = false;

      ReactGA.event({
        category: 'Notes',
        action: 'uiUpdateNotesShowStatus',
      });
    },
  },
});

const storeActions = UIStore.actions;

const {
  uiUpdateHistoryShowStatus,
  uiUpdateShoppingCartShowStatus,
  uiUpdateNotesShowStatus,
  uiShowPanel,
} = storeActions;

export {
  uiUpdateHistoryShowStatus,
  uiUpdateShoppingCartShowStatus,
  uiUpdateNotesShowStatus,
  uiShowPanel,
};

export const uiUpdateCurrentlyDraggedBoardItem = (
  item: ICurrentlyDraggedPlanItem,
) => {
  return storeActions.updateCurrentlyDraggedBoardItem(item);
};

export default UIStore.reducer;
