import React, { useContext } from 'react';
import {
  centerStyle,
  flexHorizontalStyle,
  horizontalStyle,
} from '../../styles';
import { LayoutContext } from '../../layout';
import { HeaderPlanName } from './HeaderPlanName';
import { HeaderButtonSection } from './HeaderButtonSection';

const Header = () => {
  const layoutMode = useContext(LayoutContext);

  const containerStyle = {
    ...horizontalStyle,
    height: 60,
    minHeight: 60,
    background: '#FFF',
    borderBottom: '1px solid #DDD',
  };

  const logoContainerStyle = {
    ...horizontalStyle,
    ...centerStyle,
    paddingLeft: 20,
    paddingRight: 20,
  };

  const spacerStyle = {
    ...flexHorizontalStyle,
  };

  return (
    <div style={containerStyle}>
      <div style={logoContainerStyle}>
        <img src="/logo.png" alt="Foodprep Plan" style={{ width: 60 }} />
      </div>
      {layoutMode !== 'mobile' ? (
        <HeaderPlanName />
      ) : (
        <div style={spacerStyle} />
      )}
      <HeaderButtonSection />
    </div>
  );
};

export { Header };
