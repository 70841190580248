import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from './store';
import { IRootGetState, IRootState } from './index';
import { apiBaseUrl } from '../config';

type IShoppingListStore = {
  items: IShoppingListItem[];
};

export type IShoppingListItem = {
  id: number;
  created: string;
  updated: string;
  name: string;
  completed: boolean;
  sortNr: number;
};

type IShoppingListItemNewPayload = {
  name: string;
};

type IShoppingListItemUpdatePayload = {
  id: number;
  name?: string;
  completed?: boolean;
};

const initialState: IShoppingListStore = {
  items: [],
};

const ShoppingListStore = createSlice({
  name: 'ShoppingList',
  initialState,
  reducers: {
    addItem(state, action: PayloadAction<IShoppingListItem>) {
      state.items.push(action.payload);
    },

    updateItem(state, action: PayloadAction<IShoppingListItemUpdatePayload>) {
      state.items = state.items.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
    },

    updateItems(state, action: PayloadAction<IShoppingListItem[]>) {
      state.items = action.payload;
    },

    deleteItem(state, action: PayloadAction<number>) {
      state.items = state.items.filter(item => item.id !== action.payload);
    },
  },
});

const storeActions = ShoppingListStore.actions;

export const getShoppingList = () => async (
  dispatch: AppDispatch,
  getState: IRootGetState,
) => {
  try {
    const { accessToken, id } = getState().plan;
    if (!accessToken || !id) return [];

    const res = await axios.get<IShoppingListItem[]>(
      `${apiBaseUrl}/shoppingList/?accessToken=${accessToken}&planId=${id}`,
    );

    dispatch(storeActions.updateItems(res.data));

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const addShoppingListItem = (
  item: IShoppingListItemNewPayload,
) => async (dispatch: AppDispatch, getState: IRootGetState) => {
  try {
    const sortNr = getState().shoppingList.items.length;
    const newItem = await axios.post<IShoppingListItem>(
      `${apiBaseUrl}/shoppingListItem/`,
      {
        ...item,
        token: getState().plan.accessToken,
        planId: getState().plan.id,
        sortNr,
      },
    );
    dispatch(storeActions.addItem(newItem.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateShoppingListItem = (
  item: IShoppingListItemUpdatePayload,
) => async (dispatch: AppDispatch, getState: IRootGetState) => {
  try {
    dispatch(storeActions.updateItem(item));

    await axios.put<IShoppingListItem>(
      `${apiBaseUrl}/shoppingListItem/${item.id}`,
      {
        ...item,
        token: getState().plan.accessToken,
        planId: getState().plan.id,
      },
    );
  } catch (err) {
    console.log(err);
  }
};

export const moveShoppingListItem = (
  oldIndex: number,
  newIndex: number,
) => async (dispatch: AppDispatch, getState: IRootGetState) => {
  try {
    const items = shoppingListItemsSortedSelector(getState());
    const movedItem = items[oldIndex];
    if (!movedItem) return;
    let sortNr = 0;

    const itemsSorted = items.map((item, index) => {
      if (item.id === movedItem.id) {
        return {
          ...item,
          sortNr: newIndex,
        };
      }

      // Honestly, I don't know. Some mind melting logic here.
      if (index === newIndex && newIndex < oldIndex) sortNr++;

      const itemUpdated = {
        ...item,
        sortNr,
      };
      sortNr++;
      return itemUpdated;
    });

    dispatch(storeActions.updateItems(itemsSorted));

    const sortInfoList = itemsSorted.map(item => ({
      id: item.id,
      sortNr: item.sortNr,
    }));

    await axios.post(`${apiBaseUrl}/shoppingListItem/updatePositions/`, {
      list: sortInfoList,
      token: getState().plan.accessToken,
      planId: getState().plan.id,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteShoppingListItem = (id: number) => async (
  dispatch: AppDispatch,
  getState: IRootGetState,
) => {
  await axios.delete(
    `${apiBaseUrl}/shoppingListItem/${id}?token=${
      getState().plan.accessToken
    }&planId=${getState().plan.id}`,
  );
  dispatch(storeActions.deleteItem(id));
};

// Selectors
const shoppingListItemsSelector = (state: IRootState) =>
  state.shoppingList.items;

export const shoppingListItemsSortedSelector = createSelector(
  shoppingListItemsSelector,
  items => {
    return [...items].sort((a, b) => (a.sortNr < b.sortNr ? -1 : 1));
  },
);

export const shoppingListActiveItemsCountSelector = createSelector(
  shoppingListItemsSelector,
  items => items.filter(item => !item.completed).length,
);

export default ShoppingListStore.reducer;
