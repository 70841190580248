import * as CSS from 'csstype';
import { css } from 'emotion';

export const debugStyle: CSS.Properties = {
  border: '1px solid red',
};

export const absoluteStyle: CSS.Properties = {
  position: 'absolute',
  display: 'flex',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export const flexVerticalStyle: CSS.Properties = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
};

export const flexHorizontalStyle: CSS.Properties = {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
};

export const verticalStyle: CSS.Properties = {
  display: 'flex',

  flexDirection: 'column',
};

export const horizontalStyle: CSS.Properties = {
  display: 'flex',
  flexDirection: 'row',
};

export const centerStyle = {
  justifyContent: 'center',
  alignItems: 'center',
};

export const grayLinkClass = css({
  color: '#BBB',
  '&:hover': {
    color: '#777',
  },
});

export const dangerLinkClass = css({
  color: '#e68381',
  '&:hover': {
    color: '#c1514e',
  },
});
