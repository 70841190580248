import React, { useState } from 'react';
import { generateNewPlanName } from '../../helpers/planNameHelper';
import { useDispatch, useSelector } from 'react-redux';
import { createNewPlan } from '../../store/PlanStore';
import { closePopup, popupDataSelector } from '../../store/PopupStore';
import { FormInput } from '../Form/FormInput';
import { FormButton } from '../Form/FormButton';
import { FormGroup } from '../Form/FormGroup';
import { FormLabel } from '../Form/FormLabel';
import { PopupHeader } from '../Popups/PopupHeader';
import { PopupContent } from '../Popups/PopupContent';
import { IPopupNewPlan } from '../Popups/PopupDataTypes';
import { FormInfo } from '../Form/FormInfo';
import { Spinner } from '../Spinner/Spinner';
import { FormError } from '../Form/FormError';
import { IRootState } from '../../store';

const NewPlan = () => {
  const dispatch = useDispatch();
  const popupData: IPopupNewPlan = useSelector(popupDataSelector('newPlan'));
  const currentPlanName = useSelector((state: IRootState) => state.plan.name);
  const { duplicatePlan } = popupData;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [name, setName] = useState(
    duplicatePlan ? `${currentPlanName} (kopija)` : generateNewPlanName(),
  );

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name) {
      setError('Lūdzu ievadi nosaukumu!');
      return;
    }

    setLoading(true);
    await dispatch(createNewPlan({ name, duplicatePlan }));
    setLoading(false);
    setError('');
    dispatch(closePopup('newPlan'));
  };

  const title = duplicatePlan ? 'Kopēt plānu' : 'Jauns plāns';
  const buttonTitle = duplicatePlan ? 'Kopēt' : 'Izveidot';
  const formInfoText = duplicatePlan
    ? 'Ja vēlies atkārtot iepriekš izveidotu savu plānu, veikt labojumus nemainot orģinālu vai vēlies izmantot kāda cita veidotu plānu par pamatu savam plānam - nokopē to!'
    : 'Pirms izveido jaunu plānu, saglabā esošā plāna saiti, lai arī vēlāk vari tam piekļūt! Tu vienmēr vari atrast iepriekš skatītos plānus nospiežot pogu "vēsture", bet ņem vēra, ka vēstures saraksts ir saglabāts šobrīd izmantotās pārlūkprogrammas atmiņā un pazudīs, ja tiks iztīrīts "cache"!';

  return (
    <>
      <PopupHeader
        title={title}
        onClose={() => dispatch(closePopup('newPlan'))}
      />
      <PopupContent>
        <form onSubmit={onSubmit}>
          {error ? (
            <FormError text={error} />
          ) : (
            <FormInfo text={formInfoText} />
          )}
          <FormGroup>
            <FormLabel title="Plāna nosaukums" />
            <FormInput
              value={name}
              onChange={e => setName(e.target.value)}
              autoFocus
            />
          </FormGroup>
          <FormGroup>
            {loading ? (
              <Spinner />
            ) : (
              <FormButton type="submit" title={buttonTitle} />
            )}
          </FormGroup>
        </form>
      </PopupContent>
    </>
  );
};

export { NewPlan };
