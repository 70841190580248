import React, { useContext } from 'react';
import { Panel } from './Panel';
import { HistorySidebar } from '../HistorySidebar/HistorySidebar';
import { ShoppingCart } from '../ShoppingCart/ShoppingCart';
import { Notes } from '../Notes/Notes';
import { LayoutContext } from '../../layout';
import { MenuPanel } from '../MenuPanel/MenuPanel';

const Panels = () => {
  const layoutMode = useContext(LayoutContext);

  const showPositionArrow = layoutMode !== 'mobile';
  return (
    <>
      <Panel
        name="history"
        title="Plānu vēsture"
        showPositionArrow={showPositionArrow}
      >
        <HistorySidebar />
      </Panel>
      <Panel
        name="shoppingCart"
        title="Iepirkumu saraksts"
        showPositionArrow={showPositionArrow}
      >
        <ShoppingCart />
      </Panel>
      <Panel
        name="notes"
        title="Piezīmes"
        showPositionArrow={showPositionArrow}
      >
        <Notes />
      </Panel>
      <Panel
        name="menuPanel"
        title="Izvēlne"
        showPositionArrow={showPositionArrow}
      >
        <MenuPanel />
      </Panel>
    </>
  );
};

export { Panels };
