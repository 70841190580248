import React from 'react';
import isTouchDevice from 'is-touch-device';

export const getLayoutMode = (): 'desktop' | 'tablet' | 'mobile' => {
  if (window.innerWidth >= 1024) {
    return 'desktop';
  } else {
    return 'mobile';
  }
};

export const touchDevice = isTouchDevice();

export const LayoutContext = React.createContext<
  'mobile' | 'tablet' | 'desktop'
>(getLayoutMode());
