import React, { FormEvent, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closePopup, popupDataSelector } from '../../store/PopupStore';
import { IPopupEditPlanItemData } from '../Popups/PopupDataTypes';
import { addItem, updateItem } from '../../store/PlanStore';
import { categoriesList } from './categoriesList';
import { IRootState } from '../../store';
import { PopupHeader } from '../Popups/PopupHeader';
import { PopupContent } from '../Popups/PopupContent';
import { FormGroup } from '../Form/FormGroup';
import { FormInput } from '../Form/FormInput';
import { FormButton } from '../Form/FormButton';
import { FormLabel } from '../Form/FormLabel';
import { FormSwitch } from '../Form/FormSwitch';
import { Spinner } from '../Spinner/Spinner';
import { FormColorPicker } from '../Form/FromColorPicker';
import { horizontalStyle, verticalStyle } from '../../styles';
import { FormTextArea } from '../Form/FormTextArea';
import { LayoutContext } from '../../layout';

const EditPlanItem = () => {
  const dispatch = useDispatch();
  const popupData: IPopupEditPlanItemData = useSelector(
    popupDataSelector('editPlanItem'),
  );
  const layoutMode = useContext(LayoutContext);
  const items = useSelector((state: IRootState) => state.plan.items);
  const itemData = popupData.id
    ? items.find(item => item.id === popupData.id)
    : null;
  const weekDay = itemData ? itemData.weekDay : popupData.weekDay || 1;
  const [name, setName] = useState(itemData ? itemData.name : '');
  const [categoryId, setCategoryId] = useState(
    itemData ? itemData.categoryId : 1,
  );
  const [categoryCustomName, setCategoryCustomName] = useState(
    itemData ? itemData.categoryCustomName : '',
  );
  const [color, setColor] = useState(
    itemData && itemData.color ? itemData.color : '',
  );
  const [moreInfo, setMoreInfo] = useState(
    itemData && itemData.moreInfo ? itemData.moreInfo : '',
  );
  const [showMoreInfo, setShowMoreInfo] = useState(
    itemData && itemData.moreInfo ? true : false,
  );
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setLoading(true);
    if (popupData.id) {
      await dispatch(
        updateItem({
          id: popupData.id,
          categoryId,
          categoryCustomName: categoryId === 5 ? categoryCustomName : '',
          name,
          moreInfo,
          weekDay,
          color,
        }),
      );
    } else {
      await dispatch(
        addItem({
          categoryId,
          name,
          moreInfo,
          weekDay,
          categoryCustomName: categoryId === 5 ? categoryCustomName : '',
          color,
        }),
      );
    }

    setLoading(false);

    dispatch(closePopup('editPlanItem'));
  };

  const popupTitle =
    itemData && itemData.id ? 'Labot ēdienreizi' : 'Pievienot ēdienreizi';

  const buttonTitle = itemData && itemData.id ? 'Saglabāt' : 'Pievienot';

  const categoryAndColorContainerStyle =
    layoutMode === 'mobile' ? verticalStyle : horizontalStyle;

  const colorContainerStyle =
    layoutMode === 'mobile' ? { marginTop: 20 } : { marginLeft: 20 };

  return (
    <>
      <PopupHeader
        title={popupTitle}
        onClose={() => dispatch(closePopup('editPlanItem'))}
      />
      <PopupContent>
        <form onSubmit={onSubmit}>
          <FormGroup>
            <div style={categoryAndColorContainerStyle}>
              <FormSwitch
                value={categoryId}
                list={categoriesList.map(category => ({
                  value: category.id,
                  name: category.id !== 5 ? category.name : '',
                  icon: category.id === 5 ? 'ellipsis-h' : '',
                }))}
                onChange={value => setCategoryId(value)}
              />
              <div style={colorContainerStyle}>
                <FormColorPicker
                  color={color}
                  onColorChange={colorResult => setColor(colorResult)}
                />
              </div>
            </div>
          </FormGroup>

          {categoryId === 5 && (
            <FormGroup>
              <FormLabel title="Kategorija" />
              <FormInput
                value={categoryCustomName}
                onChange={e => setCategoryCustomName(e.target.value)}
              />
            </FormGroup>
          )}

          <FormGroup>
            <FormLabel title="Nosaukums" />
            <FormInput
              autoFocus
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </FormGroup>

          {showMoreInfo ? (
            <FormGroup>
              <FormLabel title="Papildus informācija" />
              <FormTextArea
                value={moreInfo}
                onChange={e => setMoreInfo(e.target.value)}
                rows={5}
              />
            </FormGroup>
          ) : (
            <a onClick={() => setShowMoreInfo(true)}>
              <span className="fa fa-plus" /> Pievienot papildus informāciju
            </a>
          )}

          <FormGroup>
            {loading ? (
              <div
                style={{
                  ...verticalStyle,
                  height: 44,
                  justifyContent: 'center',
                }}
              >
                <Spinner />
              </div>
            ) : (
              <FormButton title={buttonTitle} />
            )}
          </FormGroup>
        </form>
      </PopupContent>
    </>
  );
};

export { EditPlanItem };
