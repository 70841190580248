import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import RootStore, { IRootState } from './index';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
const store = configureStore({ reducer: RootStore });
export type AppDispatch = typeof store.dispatch;

export type IUseDispatch = ThunkDispatch<IRootState, any, Action>;
export type IThunkAction<R> = ThunkAction<R, IRootState, undefined, Action>;

export default store;
