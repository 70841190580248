import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from './index';

type IPopupStore = {
  [key: string]: any | null;
};

const initialState: IPopupStore = {};

const PopupStore = createSlice({
  name: 'PopupStore',
  initialState,
  reducers: {
    openPopup(state, action: PayloadAction<{ name: string; data: any }>) {
      const { name, data } = action.payload;
      state[name] = data;
    },
    closePopup(state, action: PayloadAction<string>) {
      state[action.payload] = null;
    },
  },
});

const storeActions = PopupStore.actions;

export const openPopup = (name: string, data: any) => {
  return storeActions.openPopup({ name, data });
};

export const closePopup = (name: string) => {
  return storeActions.closePopup(name);
};

// Selectors
const allPopupsSelector = (state: IRootState) => state.popup;

export const popupDataSelector = (popupName: string) => {
  return createSelector(allPopupsSelector, allPopups => allPopups[popupName]);
};

export default PopupStore.reducer;
