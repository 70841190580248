import React, { useState, useEffect, useContext } from 'react';
import { IRootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { brandColorVeryLight } from '../../styles/colors';
import { FormTextArea } from '../Form/FormTextArea';
import {
  updatedUnsavedNotesStatus,
  updatePlanNotes,
} from '../../store/PlanStore';
import { flexVerticalStyle } from '../../styles';
import { LayoutContext } from '../../layout';

const saveTiemoutMs = 5000; //ms
let saveTimeoutRef: ReturnType<typeof setTimeout>;

const Notes = () => {
  const dispatch = useDispatch();
  const layoutMode = useContext(LayoutContext);
  const planNotes = useSelector((state: IRootState) => state.plan.notes);
  const unsavedNotes = useSelector(
    (state: IRootState) => state.plan.unsavedNotes,
  );
  const [noteText, setNoteText] = useState(planNotes);

  // Load note from reducer to local state
  useEffect(() => {
    setNoteText(planNotes);
  }, [planNotes]);

  const save = async (text: string) => {
    if (saveTimeoutRef) {
      clearTimeout(saveTimeoutRef);
    }
    await dispatch(updatePlanNotes(text));
    dispatch(updatedUnsavedNotesStatus(false));
  };

  const onNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updatedUnsavedNotesStatus(true));
    const text = e.target.value;
    setNoteText(text);

    if (saveTimeoutRef) {
      clearTimeout(saveTimeoutRef);
    }

    saveTimeoutRef = setTimeout(() => {
      save(text);
    }, saveTiemoutMs);
  };

  return (
    <>
      <div style={scrollStyle}>
        <FormTextArea
          style={{ border: 'none', outline: 'none', flex: 1 }}
          autoFocus={layoutMode !== 'mobile'}
          value={noteText}
          onChange={onNoteChange}
        />
      </div>
      <div style={statusBarStyle}>
        {unsavedNotes ? (
          <>
            Nesaglabātas izmaiņas!{' '}
            <b>
              <a onClick={() => save(noteText)}>Saglabāt</a>
            </b>
          </>
        ) : (
          <>Visas izmaiņas ir saglabātas!</>
        )}
      </div>
    </>
  );
};

const scrollStyle = {
  ...flexVerticalStyle,
  overflow: 'hidden' as 'hidden',
  overflowY: 'scroll' as 'scroll',
};

const statusBarStyle = {
  padding: 10,
  paddingTop: 5,
  paddingBottom: 5,
  fontSize: 13,
  color: '#AAA',
  background: brandColorVeryLight,
  borderTop: '1px solid #DDD',
};

export { Notes };
