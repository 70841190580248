import React from 'react';
import { css } from 'emotion';
import { brandColor, brandColorDarken } from '../../styles/colors';

const FormButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    title: string;
  },
) => {
  const buttonClass = css({
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 16,
    lineHeight: 1.5,
    background: brandColor,
    cursor: 'pointer',
    color: '#FFF',
    borderRadius: 4,
    fontWeight: 'bold',
    border: 'none',
    transition: '0.2s',
    outline: 0,
    '&:hover': {
      background: brandColorDarken,
    },
  });
  return (
    <button className={buttonClass} {...props}>
      {props.title}
    </button>
  );
};

export { FormButton };
