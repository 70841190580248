import React, { useContext } from 'react';
import {
  centerStyle,
  flexHorizontalStyle,
  flexVerticalStyle,
} from '../../styles';
import { css } from 'emotion';
import { brandColorDarken, brandColorVeryLight } from '../../styles/colors';
import { LayoutContext } from '../../layout';

const FormSwitch = (props: {
  value: number;
  list: { value: number; name: string; icon?: string }[];
  onChange: (value: number) => void;
}) => {
  const layoutMode = useContext(LayoutContext);
  const mobile = layoutMode === 'mobile';
  const containerStyle = mobile ? flexVerticalStyle : flexHorizontalStyle;

  return (
    <div style={containerStyle}>
      {props.list.map((item, index) => {
        const buttonClass = css({
          ...flexHorizontalStyle,
          ...centerStyle,
          cursor: 'pointer',
          color: '#666',
          border: '1px solid #DDD',
          ...(!mobile && { borderRight: 'none' }),
          ...(mobile && { borderBottom: 'none' }),
          padding: 5,
          ...(index === props.list.length - 1 && {
            ...(!mobile && {
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              borderRight: '1px solid #DDD',
            }),
            ...(mobile && {
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              borderBottom: '1px solid #DDD',
            }),
          }),
          ...(index === 0 && {
            borderTopLeftRadius: 4,
            ...(!mobile && { borderBottomLeftRadius: 4 }),
            ...(mobile && { borderTopRightRadius: 4 }),
          }),
          ...(item.value === props.value && {
            backgroundColor: brandColorVeryLight,
            color: brandColorDarken,
            fontWeight: 'bold',
          }),
          '&:hover': {
            backgroundColor: brandColorVeryLight,
            color: brandColorDarken,
          },
        });

        return (
          <a
            key={item.value}
            className={buttonClass}
            onClick={() => props.onChange(item.value)}
          >
            {item.icon ? <span className={`fa fa-${item.icon}`} /> : null}
            {item.name}
          </a>
        );
      })}
    </div>
  );
};

export { FormSwitch };
