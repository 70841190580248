export const brandColor = '#B8C781';
export const brandColorVeryLight = '#F7F8F3';
export const brandColorDarken = '#A5B46D';

export const colorPickerColors = [
  '#B8C781',
  '#c1e1c5',
  '#eb9694',
  '#fad0c3',
  '#c4def6',
  '#d4c4fb',
  'transparent',
];
