import React, { useState } from 'react';
import { IRootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { PopupHeader } from '../Popups/PopupHeader';
import { closePopup } from '../../store/PopupStore';
import { PopupContent } from '../Popups/PopupContent';
import { FormGroup } from '../Form/FormGroup';
import { FormLabel } from '../Form/FormLabel';
import { FormInput } from '../Form/FormInput';
import { FormInfo } from '../Form/FormInfo';

const SharePlan = () => {
  const dispatch = useDispatch();
  const planData = useSelector((state: IRootState) => state.plan);

  const [withWriteAccess, setWithWriteAccess] = useState(false);

  const link = `https://plan.foodprep.lv/${planData.id}/${
    withWriteAccess ? planData.accessToken : planData.shareToken
  }`;

  return (
    <>
      <PopupHeader
        title="Dalīties"
        onClose={() => dispatch(closePopup('sharePlan'))}
      />
      <PopupContent>
        <FormInfo text="Nokopē šo saiti un nošēro plānu ar citiem. Aktīvā saite dod citiem pieeju 1) bez labošanas tiesībam (read-only); 2) ar labošanas tiesībam (ja ieķeksēsi “ar labošanas tiesībām” – citi varēs rediģēt orģinālu, redzēt iepirkumu sarakst un piezīmes!). Izvēlies prātīgi!" />
        <FormGroup>
          <FormLabel title="Saite" />
          <FormInput value={link} onFocus={e => e.target.select()} readOnly />
        </FormGroup>
        {planData.accessToken ? (
          <FormGroup>
            <input
              type="checkbox"
              checked={withWriteAccess}
              onChange={() => setWithWriteAccess(!withWriteAccess)}
            />{' '}
            Ar labošanas tiesībām, iepirkumu sarakstu un piezīmēm
          </FormGroup>
        ) : null}
      </PopupContent>
    </>
  );
};

export { SharePlan };
