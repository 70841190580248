import React, { useContext, useEffect, useState } from 'react';
import { brandColor } from '../../styles/colors';
import { uiShowPanel } from '../../store/UIStore';
import {
  flexHorizontalStyle,
  flexVerticalStyle,
  horizontalStyle,
  verticalStyle,
} from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../store';
import { LayoutContext } from '../../layout';

const Panel = (props: {
  name: string;
  title: string;
  showPositionArrow?: boolean;
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const [pos, setPos] = useState('right');
  const layoutMode = useContext(LayoutContext);
  const showPanel = useSelector((state: IRootState) => state.ui.showPanel);
  const show = showPanel === props.name;

  const containerStyle = {
    ...verticalStyle,
    position: 'fixed' as 'fixed',
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.15)',
    top: 70,
    right: 0,
    borderTopLeftRadius: 5,
    bottom: 0,
    background: '#FFF',
    zIndex: 10,
    ...(pos === 'right' && {
      right: 0,
      borderTopLeftRadius: 5,
    }),
    ...(pos === 'left' && {
      left: 0,
      borderTopRightRadius: 5,
    }),
    ...(layoutMode === 'mobile' && {
      left: 10,
      right: 10,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    }),
    ...(layoutMode !== 'mobile' && { width: 400 }),
  };

  const headerStyle = {
    ...horizontalStyle,
    alignItems: 'center',
    height: 40,
    borderBottom: '1px solid #EEE',
    padding: 10,
  };

  const titleStyle = {
    ...flexHorizontalStyle,
    fontWeight: 'bold' as 'bold',
    color: brandColor,
  };

  const contentStyle = {
    ...flexVerticalStyle,
    overflow: 'hidden' as 'hidden',
  };

  useEffect(() => {
    if (show && pos === 'left') {
      setPos('right');
    }
  }, [show]);

  if (!show) return null;

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <div style={titleStyle}>{props.title}</div>
        <div>
          {props.showPositionArrow && (
            <a
              style={{ color: brandColor, marginRight: 15, fontSize: 12 }}
              onClick={() => setPos(pos === 'right' ? 'left' : 'right')}
            >
              <span
                className={
                  pos === 'right' ? 'fa fa-chevron-left' : 'fa fa-chevron-right'
                }
              />
            </a>
          )}
          <a
            style={{ color: brandColor }}
            onClick={() => dispatch(uiShowPanel(null))}
          >
            <span className="fa fa-close" />
          </a>
        </div>
      </div>
      <div style={contentStyle}>{props.children}</div>
    </div>
  );
};

export { Panel };
