import React from 'react';

const FormError = (props: { text: string }) => {
  const containerStyle = {
    padding: 10,
    borderRadius: 4,
    border: '1px solid #e74c3c',
    backgroundColor: '#FAE6E4',
    color: '#e74c3c',
  };
  return <div style={containerStyle}>{props.text}</div>;
};

export { FormError };
