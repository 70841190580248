export type IPlanHistoryItem = {
  id: number;
  name: string;
  token: string;
  readOnly: boolean;
};

const localStorageKey = 'viewedPlanHistory';

export const getLatestViewedPlan = (): IPlanHistoryItem | null => {
  try {
    // If local storage key does not exist
    const viewedPlanHistoryStr = localStorage.getItem(localStorageKey);
    if (!viewedPlanHistoryStr) return null;

    // If array is empty
    const viewedPlanHistory = JSON.parse(viewedPlanHistoryStr);
    if (!viewedPlanHistory || !viewedPlanHistory.length) return null;

    // Return latest element
    return viewedPlanHistory[viewedPlanHistory.length - 1];
  } catch (err) {
    return null;
  }
};

export const addToViewedPlanHistory = (plan: IPlanHistoryItem) => {
  try {
    // If local storage key does not exist
    const viewedPlanHistoryStr = localStorage.getItem(localStorageKey);
    if (!viewedPlanHistoryStr) {
      localStorage.setItem(localStorageKey, JSON.stringify([plan]));
      return true;
    }

    // If no any items in list
    const viewedPlanHistory: IPlanHistoryItem[] = JSON.parse(
      viewedPlanHistoryStr,
    );
    if (!viewedPlanHistory || !viewedPlanHistory.length) {
      localStorage.setItem(localStorageKey, JSON.stringify([plan]));
      return true;
    }

    // Remove plan, and push it to end
    const viewedPlanHistoryFiltered = viewedPlanHistory.filter(
      item => !(item.id === plan.id && item.token === plan.token),
    );
    viewedPlanHistoryFiltered.push(plan);
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(viewedPlanHistoryFiltered),
    );
    return true;
  } catch (err) {
    return false;
  }
};
