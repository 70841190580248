import React from 'react';

const FormInfo = (props: { text: string }) => {
  const containerStyle = {
    padding: 10,
    borderRadius: 4,
    border: '1px solid #DDD',
    backgroundColor: '#F4F4F4',
    color: '#AAA',
  };
  return <div style={containerStyle}>{props.text}</div>;
};

export { FormInfo };
