import React from 'react';
import { brandColor } from '../../styles/colors';
import { centerStyle, verticalStyle } from '../../styles';

const FormCheckbox = ({
  size,
  checked,
  onChange,
}: {
  size: number;
  checked: boolean;
  onChange: (status?: boolean) => void;
}) => (
  <a
    style={{
      ...verticalStyle,
      ...centerStyle,
      width: size,
      height: size,
      border: `2px solid ${brandColor}`,
      borderRadius: 4,
      backgroundColor: checked ? brandColor : 'transparent',
      cursor: 'pointer',
      color: '#FFF',
      fontSize: size * 0.7,
    }}
    onClick={e => {
      e.stopPropagation();
      onChange(!checked);
    }}
  >
    {checked && <span className="fa fa-check" style={{ display: 'block' }} />}
  </a>
);

export { FormCheckbox };
