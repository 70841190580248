import moment from 'moment';

const monthNames = [
  'Janvāris',
  'Februāris',
  'Marts',
  'Aprīlis',
  'Maijs',
  'Jūnijs',
  'Jūlijs',
  'Augusts',
  'Septembris',
  'Oktobris',
  'Novembris',
  'Decembris',
];

export const generateNewPlanName = () => {
  const from = moment().startOf('isoWeek');
  const to = moment().endOf('isoWeek');
  if (moment().isoWeekday() > 5) {
    from.add(1, 'week');
    to.add(1, 'week');
  }

  const fromMonth = monthNames[from.month()];
  const toMonth = monthNames[to.month()];

  const fromDay = from.date();
  const toDay = to.date();
  const year = to.year();

  return `${fromDay}. ${fromMonth} - ${toDay}. ${toMonth}, ${year}`;
};
