import React, { useContext } from 'react';
import ReactPopup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { closePopup, popupDataSelector } from '../../store/PopupStore';
import { LayoutContext } from '../../layout';

const Popup = (props: { name: string; children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const popupData = useSelector(popupDataSelector(props.name));
  const layoutMode = useContext(LayoutContext);

  if (!popupData) return null;

  const onClose = () => {
    dispatch(closePopup(props.name));
  };

  return (
    <ReactPopup
      open
      onClose={onClose}
      position="center center"
      modal
      contentStyle={{
        borderRadius: 4,
        padding: 0,
        width: layoutMode === 'mobile' ? '95%' : '50%',
      }}
      closeOnDocumentClick={false}
    >
      <>{props.children}</>
    </ReactPopup>
  );
};

export { Popup };
