import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import { absoluteStyle } from './styles';
import { PlanSheet } from './components/PlanSheet/PlanSheet';
import { Header } from './components/Header/Header';
import { Popups } from './components/Popups/Popups';
import { Panels } from './components/Panels/Panels';
import { loadPlan } from './store/PlanStore';
import { history } from './history';
import { IUseDispatch } from './store/store';
import { getLayoutMode, LayoutContext } from './layout';

ReactGA.initialize('UA-106205055-2');

function App() {
  const [layoutMode, setLayoutMode] = useState(getLayoutMode());
  const dispatch = useDispatch<IUseDispatch>();
  const [loading, setLoading] = useState(true);

  // Layout mode
  useEffect(() => {
    const resizeListener = () => {
      if (layoutMode !== getLayoutMode()) {
        setLayoutMode(getLayoutMode());
      }
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [layoutMode]);

  // Initial load
  useEffect(() => {
    const initialLoad = async () => {
      const { pathname } = history.location;
      const planAccessData: { id: number; token: string } | null = {
        id: 0,
        token: '',
      };

      if (pathname) {
        const pp = pathname.split('/');
        if (pp[1] && pp[2]) {
          planAccessData.id = Number(pp[1]);
          planAccessData.token = pp[2];
        }
      }
      const plan = await dispatch(loadPlan(planAccessData));
      if (plan) {
        history.push(
          `/${plan.id}/${plan.readOnly ? plan.shareToken : plan.accessToken}`,
        );
      }

      setLoading(false);
    };

    initialLoad();
  }, []);

  const appContainerStyle = {
    ...absoluteStyle,
    flexDirection: 'column' as 'column',
    background: '#EEE',
  };

  return (
    <LayoutContext.Provider value={layoutMode}>
      <div style={appContainerStyle}>
        <Header />
        <PlanSheet />
        <Panels />
        <Popups />
      </div>
    </LayoutContext.Provider>
  );
}

export default App;
