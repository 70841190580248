import React from 'react';
import { useDispatch } from 'react-redux';
import { flexVerticalStyle } from '../../styles';
import { brandColor } from '../../styles/colors';
import { uiShowPanel } from '../../store/UIStore';
import { IPlanHistoryItem } from '../../helpers/planHistoryHelper';
import { history } from '../../history';
import { loadPlan } from '../../store/PlanStore';
import { css } from 'emotion';

const HistorySidebar = () => {
  const dispatch = useDispatch();

  const viewedPlanHistoryStr =
    localStorage.getItem('viewedPlanHistory') || null;
  const viewedHistory = viewedPlanHistoryStr
    ? JSON.parse(viewedPlanHistoryStr)
    : [];
  viewedHistory.reverse();

  const contentStyle = {
    ...flexVerticalStyle,
    overflow: 'hidden' as 'hidden',
    overflowY: 'scroll' as 'scroll',
  };

  const itemClass = css({
    padding: 10,
    borderBottom: '1px solid #EEE',
    color: '#888',
    '&:hover': {
      backgroundColor: '#f6f7f4',
      color: brandColor,
    },
  });

  return (
    <div style={contentStyle}>
      {viewedHistory.map((item: IPlanHistoryItem) => (
        <a
          onClick={() => {
            history.push(`/${item.id}/${item.token}`);
            dispatch(loadPlan({ id: item.id, token: item.token }));
            dispatch(uiShowPanel(null));
          }}
          key={`${item.id}-${item.token}`}
          className={itemClass}
        >
          <div style={{ fontSize: 12, color: '#BBB' }}>
            {item.readOnly && <span className="fa fa-lock" />} #{item.id}
          </div>
          <div>{item.name}</div>
        </a>
      ))}
    </div>
  );
};

export { HistorySidebar };
