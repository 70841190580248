import React, { DragEvent, useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlanItem } from './PlanItem';
import {
  centerStyle,
  flexHorizontalStyle,
  flexVerticalStyle,
  horizontalStyle,
} from '../../styles';
import { brandColor } from '../../styles/colors';
import { itemsForWeekDaySelector, moveItem } from '../../store/PlanStore';
import { openPopup } from '../../store/PopupStore';
import { uiUpdateCurrentlyDraggedBoardItem } from '../../store/UIStore';
import { IRootState } from '../../store';
import { LayoutContext } from '../../layout';
import { touchDevice } from '../../layout';

const PlanColumn = (props: { weekDay: number; title: string }) => {
  const dispatch = useDispatch();
  const items = useSelector(itemsForWeekDaySelector(props.weekDay));
  const readOnly = useSelector((state: IRootState) => state.plan.readOnly);
  const currentlyDraggedPlanItem = useSelector(
    (state: IRootState) => state.ui.currentlyDraggedPlanItem,
  );
  const layoutMode = useContext(LayoutContext);

  const dragCounter = useRef(0);
  const listRef = useRef<HTMLDivElement>(null);

  const [dropPlaceholderPos, setDropPlacholderPos] = useState<number | null>(
    null,
  );
  const [hover, setHover] = useState(false);

  const getAllItemPositionMap = () => {
    const posMap: { index: number; y: number }[] = [];

    if (listRef && listRef.current && listRef.current.children.length) {
      const nodeList = Array.from(listRef.current.children);
      nodeList.forEach((node, index) => {
        posMap.push({
          index,
          y:
            node.getBoundingClientRect().top +
            node.getBoundingClientRect().height / 2,
        });
      });
    }

    return posMap;
  };

  const onAddClick = () => {
    dispatch(openPopup('editPlanItem', { weekDay: props.weekDay }));
  };

  const onDragEnter = (e: DragEvent) => {
    // hacky fix to prevent refiring onDragEnter / onDragLeave when dragged over children
    e.preventDefault();
    dragCounter.current++;
  };

  const onDragLeave = () => {
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDropPlacholderPos(null);
    }
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    // Get index where drop placeholder should be displayed
    let index;
    const allItemPositionMap = getAllItemPositionMap();

    if (allItemPositionMap.length) {
      const findItemPos = allItemPositionMap.find(map => e.pageY < map.y);
      if (findItemPos) index = findItemPos.index;
      else index = allItemPositionMap.length;
    } else {
      index = 0;
    }

    // Update drop placeholder pos if index has changed
    if (dropPlaceholderPos !== index) {
      if (
        currentlyDraggedPlanItem !== null &&
        currentlyDraggedPlanItem.weekDay === props.weekDay &&
        (index === currentlyDraggedPlanItem.index ||
          index === currentlyDraggedPlanItem.index + 1)
      ) {
        setDropPlacholderPos(null);
      } else {
        setDropPlacholderPos(index);
      }
    }
  };

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!currentlyDraggedPlanItem) return;

    let index = dropPlaceholderPos;
    if (index === null) return;

    if (
      currentlyDraggedPlanItem.weekDay === props.weekDay &&
      currentlyDraggedPlanItem.index < index
    ) {
      index--;
    }

    dispatch(
      moveItem({
        itemId: currentlyDraggedPlanItem.itemId,
        newWeekDay: props.weekDay,
        newIndex: dropPlaceholderPos || 0,
      }),
    );

    setDropPlacholderPos(null);
    dispatch(uiUpdateCurrentlyDraggedBoardItem(null));
    dragCounter.current = 0;
  };

  const columnContainerStyle = {
    ...flexVerticalStyle,
    minWidth: layoutMode === 'mobile' ? 240 : 145,
    borderRight: '1px solid #DDD',
  };

  const titleStyle = {
    ...flexHorizontalStyle,
    fontWeight: 'bold' as 'bold',
    color: '#AAA',
  };

  const renderDropPlacholder = (show: boolean) => {
    if (!show) {
      return <div style={{ height: 2 }} />;
    }

    return <div style={{ height: 2, background: '#CCC' }} />;
  };

  return (
    <div
      style={columnContainerStyle}
      onMouseEnter={() => {
        if (!readOnly) {
          setHover(true);
        }
      }}
      onMouseLeave={() => setHover(false)}
    >
      <div style={{ ...horizontalStyle, padding: 10 }}>
        <div style={titleStyle}>{props.title}</div>
        <div style={{ ...horizontalStyle, ...centerStyle }}>
          {(hover || (touchDevice && !readOnly)) && (
            <a
              style={{ color: brandColor, cursor: 'pointer' }}
              onClick={onAddClick}
            >
              <span className="fa fa-plus-circle" />
            </a>
          )}
        </div>
      </div>
      <div
        style={{ ...flexVerticalStyle, padding: 5 }}
        ref={listRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        {items.map((item, index) => (
          <div key={item.id}>
            {renderDropPlacholder(
              dropPlaceholderPos !== null && dropPlaceholderPos === index,
            )}
            <PlanItem
              data={item}
              onDragStart={() => {
                dispatch(
                  uiUpdateCurrentlyDraggedBoardItem({
                    index,
                    itemId: item.id,
                    weekDay: item.weekDay,
                  }),
                );
              }}
              onDragEnd={() => {
                dispatch(uiUpdateCurrentlyDraggedBoardItem(null));
              }}
              index={index}
              listLength={items.length}
            />
            {renderDropPlacholder(
              dropPlaceholderPos !== null &&
                dropPlaceholderPos === items.length &&
                index + 1 === dropPlaceholderPos,
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export { PlanColumn };
