import { combineReducers } from 'redux';

import PlanStore from './PlanStore';
import PopupStore from './PopupStore';
import ShoppingListStore from './ShoppingListStore';
import UIStore from './UIStore';

const rootReducer = combineReducers({
  plan: PlanStore,
  popup: PopupStore,
  shoppingList: ShoppingListStore,
  ui: UIStore,
});

export type IRootState = ReturnType<typeof rootReducer>;
export type IRootGetState = () => IRootState;

export default rootReducer;
