import React, { MouseEvent, useState } from 'react';
import { horizontalStyle } from '../../styles';
import { brandColor } from '../../styles/colors';

const HeaderDropdownItem = (props: {
  active?: boolean;
  text?: string;
  icon?: string;
  badgeCount?: number;
  onClick?: (e: MouseEvent) => void;
  style?: any;
}) => {
  const { active, style } = props;
  const [hover, setHover] = useState(false);

  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  const activeColor = brandColor;
  const activeBgColor = '#f6f7f4';
  const grayColor = '#888';

  const containerStyle = {
    ...horizontalStyle,
    cursor: 'pointer',
    padding: 12,
    ...((hover || active) && { background: activeBgColor }),
    borderTop: '1px solid #EEE',
    borderLeft: active ? `3px solid ${activeColor}` : '3px solid transparent',
    ...style,
  };

  const textStyle = {
    fontSize: 12,
    color: hover || active ? activeColor : grayColor,
  };

  const iconStyle = {
    fontSize: 15,
    color: activeColor,
    marginRight: 8,
  };

  const iconClass = `fa fa-${props.icon}`;

  const badgeStyle = {
    fontSize: 10,
    height: 16,
    width: 16,
    borderRadius: 8,
    textAlign: 'center' as 'center',
    background: '#e74c3c',
    color: '#FFF',
  };

  return (
    <a
      style={containerStyle}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {props.icon ? <span className={iconClass} style={iconStyle} /> : null}
      <span style={textStyle}>{props.text}</span>
      <span style={{ display: 'flex', flex: 1 }} />
      {props.badgeCount ? (
        <span style={badgeStyle}>{props.badgeCount}</span>
      ) : null}
    </a>
  );
};

export { HeaderDropdownItem };
