import React from 'react';
import { useDispatch } from 'react-redux';
import { PopupHeader } from '../Popups/PopupHeader';
import { closePopup } from '../../store/PopupStore';
import { PopupContent } from '../Popups/PopupContent';

const PrivacyInfo = () => {
  const dispatch = useDispatch();

  return (
    <>
      <PopupHeader
        title="Privātums"
        onClose={() => dispatch(closePopup('privacyInfo'))}
      />
      <PopupContent>
        <p>
          1. Tavi izveidotie plāni tiek saglabāti mūsu datubāzē <b>anonīmi</b> -
          nepiesaistīti lietotāja vārdam vai IP adresei.
        </p>
        <p>
          2. Skatīto plānu vēsture tiek saglabāta Tavas pārlūkprogrammas atmiņā
          (<i>LocalStorage</i>)
        </p>
        <p>3. Datubāzē saglabātos datus mēs nenodosim trešajām pusēm.</p>
        <p>
          4. Jebkāda veida saglabāto datu analizēšana, lai iegūtu statistiku par
          Foodprep ēdienreižu plānotāja izmantošanu, tiks veikta tikai anonīmā
          (dati jau sākotnēji tiek saglabāti anonīmi) un apkopotā veidā!
        </p>
        <p>
          5. Lai uzskaitītu ēdienu plānotāja izmantošanas statistiku (cik bieži
          tas tiek izmantots, kādas ir biežāk izmantotās funkcijas, utml.) tiek
          izmantots Google Analytics
        </p>
      </PopupContent>
    </>
  );
};

export { PrivacyInfo };
