import React, { useState, MouseEvent, useRef, useEffect } from 'react';
import { centerStyle, verticalStyle } from '../../styles';
import { brandColor } from '../../styles/colors';

const dropdownYOffset = 10;
const dropdownWidth = 250;

const HeaderButton = (props: {
  active?: boolean;
  text?: string;
  helpTitle?: string;
  icon?: string;
  iconSize?: number;
  onClick?: (e: MouseEvent) => void;
  dropdownEnabled?: boolean;
  badgeCount?: number;
  badgeIcon?: string | null;
  children?: React.ReactNode;
  style?: any;
}) => {
  const { active, style, dropdownEnabled } = props;
  const [hover, setHover] = useState(false);
  const [dropdownCoords, setDropdownCoords] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const buttonRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const addClickEventListener = () => {
      setDropdownCoords(null);
    };

    if (dropdownEnabled && dropdownCoords) {
      document.addEventListener('click', addClickEventListener);
    }

    return () => {
      document.removeEventListener('click', addClickEventListener);
    };
  }, [dropdownCoords]);

  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (dropdownEnabled) {
      toggleDropdown();
    }

    if (props.onClick) {
      props.onClick(e);
    }
  };

  const toggleDropdown = () => {
    if (buttonRef.current) {
      const y = buttonRef.current.clientHeight + dropdownYOffset;
      let x = buttonRef.current.offsetLeft;
      if (x + dropdownWidth > window.innerWidth) {
        x = window.innerWidth - dropdownWidth - 3;
      }

      if (!dropdownCoords) {
        setDropdownCoords({ x, y });
      } else {
        setDropdownCoords(null);
      }
    }
  };

  const activeColor = brandColor;
  const activeBgColor = '#f6f7f4';
  const grayColor = '#888';

  const containerStyle = {
    ...verticalStyle,
    ...centerStyle,
    position: 'relative',
    width: 60,
    cursor: 'pointer',
    userSelect: 'none',
    borderTop: active ? `3px solid ${activeColor}` : '3px solid transparent',
    ...((hover || active) && { background: activeBgColor }),
    ...style,
  };

  const textStyle = {
    fontSize: 12,
    color: hover || active ? activeColor : grayColor,
    marginTop: 4,
  };

  const badgeStyle = {
    position: 'absolute' as 'absolute',
    top: 10,
    right: 10,
    fontSize: 10,
    height: 16,
    width: 16,
    borderRadius: 8,
    textAlign: 'center' as 'center',
    background: '#e74c3c',
    color: '#FFF',
  };

  const iconStyle = {
    fontSize: props.iconSize || 15,
    color: activeColor,
  };

  const iconClass = `fa fa-${props.icon}`;

  const dropDownStyle = {
    position: 'fixed' as 'fixed',
    top: dropdownCoords ? dropdownCoords.y : 0,
    left: dropdownCoords ? dropdownCoords.x : 0,
    width: dropdownWidth,
    zIndex: 100,
    background: '#FFF',
    borderRadius: 5,
    boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.25)',
    overflow: 'hidden',
  };

  return (
    <>
      <a
        style={containerStyle}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        ref={buttonRef}
        title={props.helpTitle || ''}
      >
        {props.icon ? <span className={iconClass} style={iconStyle} /> : null}
        <span style={textStyle}>{props.text}</span>
        {props.badgeCount ? (
          <span style={badgeStyle}>{props.badgeCount}</span>
        ) : null}
        {props.badgeIcon ? (
          <span style={badgeStyle}>
            <span
              className={`fa fa-${props.badgeIcon}`}
              style={{ fontSize: 10 }}
            />
          </span>
        ) : null}
      </a>
      {dropdownCoords && (
        <div style={dropDownStyle} onClick={() => toggleDropdown()}>
          {props.children}
        </div>
      )}
    </>
  );
};

export { HeaderButton };
