import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopupHeader } from '../Popups/PopupHeader';
import { closePopup, popupDataSelector } from '../../store/PopupStore';
import { PopupContent } from '../Popups/PopupContent';
import { css } from 'emotion';
import nl2br from 'react-nl2br';
import { brandColor, brandColorDarken } from '../../styles/colors';
import { IPopupMoreInfo } from '../Popups/PopupDataTypes';

const MoreInfo = () => {
  const dispatch = useDispatch();
  const popupData: IPopupMoreInfo = useSelector(popupDataSelector('moreInfo'));

  const linkClass = css({
    color: brandColor,
    '&:hover': {
      color: brandColorDarken,
    },
  });

  return (
    <>
      <PopupHeader
        title={popupData.name}
        onClose={() => dispatch(closePopup('moreInfo'))}
      />
      <PopupContent>
        <div style={{ userSelect: 'text' }}>{nl2br(popupData.moreInfo)}</div>
      </PopupContent>
    </>
  );
};

export { MoreInfo };
