import React, { useState } from 'react';
import { GithubPicker, ColorChangeHandler } from 'react-color';
import { colorPickerColors } from '../../styles/colors';
import { centerStyle, flexHorizontalStyle } from '../../styles';

const FormColorPicker = ({
  color,
  onColorChange,
}: {
  color: string;
  onColorChange: (color: string) => void;
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange: ColorChangeHandler = ({ hex }) => {
    onColorChange(hex !== 'transparent' ? hex : '');
    setDisplayColorPicker(false);
  };

  const styles = {
    color: {
      ...flexHorizontalStyle,
      ...centerStyle,
      minWidth: 36,
      height: 22,
      borderRadius: 4,
      background: color,
    },
    swatch: {
      padding: 5,
      background: '#fff',
      borderRadius: 4,
      border: '1px solid #DDD',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute' as 'absolute',
      zIndex: 2,
    },
    cover: {
      position: 'fixed' as 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color}>
          {!color ? (
            <span
              className="fa fa-tint"
              style={{ color: '#CCC', fontSize: 18 }}
            />
          ) : null}
        </div>
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <GithubPicker
            color={color}
            onChange={handleChange}
            colors={colorPickerColors}
          />
        </div>
      ) : null}
    </div>
  );
};

export { FormColorPicker };
