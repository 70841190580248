import React from 'react';

const PopupContent = (props: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      {props.children}
    </div>
  );
};

export { PopupContent };
