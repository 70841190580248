import React from 'react';
import { openPopup } from '../../store/PopupStore';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../store';
import { HeaderDropdownItem } from '../Header/HeaderDropdownItem';
import { HeaderPlanName } from '../Header/HeaderPlanName';
import { uiShowPanel } from '../../store/UIStore';
import { shoppingListActiveItemsCountSelector } from '../../store/ShoppingListStore';

const MenuPanel = () => {
  const dispatch = useDispatch();
  const readOnly = useSelector((state: IRootState) => state.plan.readOnly);
  const showPanel = useSelector((state: IRootState) => state.ui.showPanel);
  const shoppingListActiveItemsCount = useSelector(
    shoppingListActiveItemsCountSelector,
  );

  return (
    <div style={{ overflow: 'hidden', overflowY: 'scroll' }}>
      <div style={{ padding: 10 }}>
        <HeaderPlanName />
      </div>

      <HeaderDropdownItem
        text="Jauns plāns"
        icon="file-o"
        onClick={() => dispatch(openPopup('newPlan', { duplicatePlan: false }))}
      />

      <HeaderDropdownItem
        text="Kopēt plānu"
        icon="copy"
        onClick={() => dispatch(openPopup('newPlan', { duplicatePlan: true }))}
      />

      {!readOnly && (
        <HeaderDropdownItem
          icon="share"
          text="Dalīties"
          onClick={() => dispatch(openPopup('sharePlan', {}))}
        />
      )}

      {!readOnly && (
        <HeaderDropdownItem
          icon="shopping-cart"
          text="Iepirkumu saraksts"
          badgeCount={shoppingListActiveItemsCount}
          onClick={() =>
            dispatch(
              uiShowPanel(showPanel !== 'shoppingCart' ? 'shoppingCart' : null),
            )
          }
          active={showPanel === 'shoppingCart'}
        />
      )}

      {!readOnly && (
        <HeaderDropdownItem
          icon="book"
          text="Piezīmes"
          onClick={() =>
            dispatch(uiShowPanel(showPanel !== 'notes' ? 'notes' : null))
          }
          active={showPanel === 'notes'}
        />
      )}

      <HeaderDropdownItem
        icon="folder"
        text="Skatīto plānu vēsture"
        onClick={() =>
          dispatch(uiShowPanel(showPanel !== 'history' ? 'history' : null))
        }
        active={showPanel === 'history'}
      />

      <HeaderDropdownItem
        text="Blogs"
        onClick={() => window.open('https://foodprep.lv')}
      />

      <HeaderDropdownItem
        text="Privātums"
        onClick={() => dispatch(openPopup('privacyInfo', {}))}
      />
    </div>
  );
};

export { MenuPanel };
