import React, { useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { flexVerticalStyle, horizontalStyle } from '../../styles';
import { brandColorVeryLight } from '../../styles/colors';
import { FormInput } from '../Form/FormInput';
import { FormButton } from '../Form/FormButton';
import { ShoppingCartItem } from './ShoppingCartItem';
import {
  addShoppingListItem,
  IShoppingListItem,
  moveShoppingListItem,
  shoppingListItemsSortedSelector,
  updateShoppingListItem,
} from '../../store/ShoppingListStore';
import store from '../../store/store';
import { LayoutContext, touchDevice } from '../../layout';

const ShoppingCart = () => {
  const dispatch = useDispatch();
  const layoutMode = useContext(LayoutContext);
  const scrollRef = useRef<HTMLDivElement>(null);
  const shoppingListItems = useSelector(shoppingListItemsSortedSelector);
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!name || !name.length) {
      setError(true);
      return;
    }

    setLoading(true);

    await dispatch(
      addShoppingListItem({
        name,
      }),
    );

    setName('');
    setError(false);
    setLoading(false);

    // Scroll to bottom
    if (scrollRef.current) {
      const scrollHeight = scrollRef.current.scrollHeight || 0;
      scrollRef.current.scrollTo(0, scrollHeight);
    }
  };

  return (
    <>
      <div
        style={{
          ...flexVerticalStyle,
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
        ref={scrollRef}
      >
        <SortableShoppingList
          distance={!touchDevice ? 5 : 0}
          pressDelay={touchDevice ? 200 : 0}
          items={shoppingListItems}
          onSortEnd={e =>
            dispatch(moveShoppingListItem(e.oldIndex, e.newIndex))
          }
        />
      </div>

      <div
        style={{
          padding: 10,
          background: brandColorVeryLight,
          borderTop: '1px solid #DDD',
        }}
      >
        <form style={horizontalStyle} onSubmit={onFormSubmit}>
          <FormInput
            style={{
              marginRight: 10,
              ...(error && {
                border: '1px solid #eb9694',
              }),
            }}
            autoFocus={layoutMode !== 'mobile'}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <FormButton
            title={loading ? 'Saglabājas..' : 'Pievienot'}
            style={{ width: 185 }}
          />
        </form>
      </div>
    </>
  );
};

const SortableShoppingListItem = SortableElement(
  ({ value }: { value: IShoppingListItem }) => (
    <ShoppingCartItem
      id={value.id}
      title={value.name}
      completed={value.completed}
      sortNr={value.sortNr}
      onStatusChange={() => {
        store.dispatch(
          updateShoppingListItem({
            id: value.id,
            completed: !value.completed,
          }),
        );
      }}
    />
  ),
);

const SortableShoppingList = SortableContainer(
  ({ items }: { items: IShoppingListItem[] }) => {
    return (
      <ul style={{ padding: 0, margin: 0 }}>
        {items.map((value, index) => (
          <SortableShoppingListItem
            key={`item-${value.id}`}
            index={index}
            value={value}
          />
        ))}
      </ul>
    );
  },
);

export { ShoppingCart };
