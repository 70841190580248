import React from 'react';
import { flexHorizontalStyle, horizontalStyle } from '../../styles';
import { css } from 'emotion';
import { brandColor, brandColorDarken } from '../../styles/colors';

const PopupHeader = (props: { title: string; onClose?: Function }) => {
  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const containerStyle = {
    ...horizontalStyle,
    padding: 20,
    borderBottom: '1px solid #EEE',
  };

  const titleStyle = {
    ...flexHorizontalStyle,
    fontSize: 24,
    fontWeight: 'bold' as 'bold',
    color: '#777',
  };

  const closeButtonContainerStyle = {
    ...horizontalStyle,
    marginLeft: 20,
    alignItems: 'center',
  };

  const closeButtonClass = css({
    color: brandColor,
    fontSize: 18,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: brandColorDarken,
    },
  });

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>{props.title}</div>
      <div style={closeButtonContainerStyle}>
        <a className={closeButtonClass} onClick={onClose}>
          <span className="fa fa-close" />
        </a>
      </div>
    </div>
  );
};

export { PopupHeader };
