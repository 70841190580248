import React, { DragEvent, useState } from 'react';
import {
  deleteItem,
  duplicateItem,
  IPlanItem,
  moveItem,
} from '../../store/PlanStore';
import { categoriesList } from '../EditPlanItem/categoriesList';
import {
  centerStyle,
  dangerLinkClass,
  flexHorizontalStyle,
  flexVerticalStyle,
  grayLinkClass,
  horizontalStyle,
  verticalStyle,
} from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { openPopup } from '../../store/PopupStore';
import { IRootState } from '../../store';
import { touchDevice } from '../../layout';

const PlanItem = ({
  data,
  onDragStart,
  onDragEnd,
  index,
  listLength,
}: {
  data: IPlanItem;
  onDragStart: (e: DragEvent<HTMLDivElement>) => void;
  onDragEnd: (e: DragEvent<HTMLDivElement>) => void;
  index: number;
  listLength: number;
}) => {
  const dispatch = useDispatch();
  const readOnly = useSelector((state: IRootState) => state.plan.readOnly);
  const [hover, setHover] = useState(false);
  const [showTouchControls, setShowTouchControls] = useState(false);
  const [showMoveControls, setShowMoveControls] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { color, categoryId, name, moreInfo } = data;

  const categoryData = categoriesList.find(item => item.id === categoryId);

  let categoryName = '';
  if (categoryData) {
    if (categoryData.id === 5) {
      categoryName = data.categoryCustomName || '';
    } else {
      categoryName = categoryData.name;
    }
  }

  const onEditClick = () => {
    dispatch(openPopup('editPlanItem', { id: data.id }));
  };

  const onDuplicateClick = () => {
    dispatch(duplicateItem(data.id));
  };

  const onDeleteClick = () => {
    setConfirmDelete(true);
  };

  const onDeleteConfirmClick = () => {
    dispatch(deleteItem(data.id));
  };

  const buttonContainerStyle = {
    ...verticalStyle,
    ...centerStyle,
    width: 25,
    fontSize: 14,
  };

  const mobileButtonContainerStyle = {
    ...horizontalStyle,
    ...centerStyle,
    borderTop: '1px solid #EEE',
    fontSize: 18,
  };

  const cardStyle = {
    background: '#FFF',
    borderRadius: 5,
    border: '1px solid #DEDEDE',
    fontSize: 12,
    color: '#444',
    marginTop: 5,
    marginBottom: 5,
    borderLeftColor: color ? color : '#DEDEDE',
    borderLeftWidth: color ? 3 : 1,
  };

  const mobileButtonStyle = {
    ...flexHorizontalStyle,
    ...centerStyle,
    height: 50,
    color: '#CCC',
  };

  const buttonStyle = {
    cursor: 'pointer',
    color: '#CCC',
  };

  // Delete confirm
  if (confirmDelete) {
    return (
      <div
        style={{
          ...cardStyle,
          ...flexHorizontalStyle,
          ...centerStyle,
          minHeight: 100,
        }}
      >
        <a
          onClick={onDeleteConfirmClick}
          className={dangerLinkClass}
          style={{ marginRight: 10, fontWeight: 'bold' }}
        >
          Izdzēst
        </a>
        <a className={grayLinkClass} onClick={() => setConfirmDelete(false)}>
          Atcelt
        </a>
      </div>
    );
  }

  const renderButtons = (show: boolean, mobile: boolean) => {
    return (
      <div style={mobile ? mobileButtonContainerStyle : buttonContainerStyle}>
        {show && (
          <>
            <a
              style={mobile ? mobileButtonStyle : buttonStyle}
              onClick={onEditClick}
            >
              <span className="fa fa-pencil" />
            </a>
            <a
              style={mobile ? mobileButtonStyle : buttonStyle}
              onClick={onDuplicateClick}
            >
              <span className="fa fa-copy" />
            </a>
            <a
              style={mobile ? mobileButtonStyle : buttonStyle}
              onClick={onDeleteClick}
            >
              <span className="fa fa-trash" />
            </a>

            {touchDevice && (
              <a
                style={mobile ? mobileButtonStyle : buttonStyle}
                onClick={e => {
                  e.stopPropagation();
                  setShowTouchControls(false);
                  setShowMoveControls(true);
                }}
              >
                <span className="fa fa-arrows" />
              </a>
            )}
          </>
        )}
      </div>
    );
  };

  const renderMoveButtons = () => {
    return (
      <div style={mobileButtonContainerStyle}>
        {data.weekDay !== 1 ? (
          <a
            style={mobileButtonStyle}
            onClick={e => {
              e.stopPropagation();
              dispatch(
                moveItem({
                  newIndex: 0,
                  newWeekDay: data.weekDay - 1,
                  itemId: data.id,
                }),
              );
            }}
          >
            <span className="fa fa-chevron-left" />
          </a>
        ) : (
          <div style={mobileButtonStyle} />
        )}
        {data.weekDay !== 7 ? (
          <a
            style={mobileButtonStyle}
            onClick={e => {
              e.stopPropagation();
              dispatch(
                moveItem({
                  newIndex: 0,
                  newWeekDay: data.weekDay + 1,
                  itemId: data.id,
                }),
              );
            }}
          >
            <span className="fa fa-chevron-right" />
          </a>
        ) : (
          <div style={mobileButtonStyle} />
        )}
        {index !== 0 ? (
          <a
            style={mobileButtonStyle}
            onClick={e => {
              e.stopPropagation();
              dispatch(
                moveItem({
                  newIndex: index - 1,
                  newWeekDay: data.weekDay,
                  itemId: data.id,
                }),
              );
            }}
          >
            <span className="fa fa-chevron-up" />
          </a>
        ) : (
          <div style={mobileButtonStyle} />
        )}
        {index !== listLength - 1 ? (
          <a
            style={mobileButtonStyle}
            onClick={e => {
              e.stopPropagation();
              dispatch(
                moveItem({
                  newIndex: index + 1,
                  newWeekDay: data.weekDay,
                  itemId: data.id,
                }),
              );
            }}
          >
            <span className="fa fa-chevron-down" />
          </a>
        ) : (
          <div style={mobileButtonStyle} />
        )}
      </div>
    );
  };

  return (
    <div
      style={cardStyle}
      draggable={!readOnly}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onMouseEnter={() => {
        if (!readOnly && !touchDevice) {
          setHover(true);
        }
      }}
      onClick={() => {
        if (!readOnly && touchDevice) {
          setShowMoveControls(false);
          setShowTouchControls(!showTouchControls);
        }
      }}
      onMouseLeave={() => setHover(false)}
    >
      <div style={{ ...horizontalStyle, minHeight: 100 }}>
        <div style={{ ...flexVerticalStyle }}>
          {categoryName ? (
            <div style={{ padding: 5, color: '#BBB' }}>{categoryName}</div>
          ) : null}
          <div style={{ fontSize: 14, padding: 5 }}>{name}</div>

          {moreInfo ? (
            <div style={{ padding: 5 }}>
              <a
                style={{ color: '#BBB' }}
                onClick={() =>
                  dispatch(
                    openPopup('moreInfo', { name, moreInfo, categoryId }),
                  )
                }
              >
                <span
                  className="fa fa-info-circle"
                  style={{ marginRight: 3 }}
                />
                Vairāk info
              </a>
            </div>
          ) : null}
        </div>
        {!touchDevice && renderButtons(hover, false)}
      </div>

      {touchDevice && showTouchControls && (
        <div>{renderButtons(true, true)}</div>
      )}

      {touchDevice && showMoveControls && <div>{renderMoveButtons()}</div>}
    </div>
  );
};

export { PlanItem };
