import React, { useState } from 'react';
import { css } from 'emotion';
import { brandColor } from '../../styles/colors';
import { flexHorizontalStyle, horizontalStyle } from '../../styles';
import { FormCheckbox } from '../Form/FormCheckbox';
import { FormInput } from '../Form/FormInput';
import { useDispatch } from 'react-redux';
import {
  deleteShoppingListItem,
  updateShoppingListItem,
} from '../../store/ShoppingListStore';

const ShoppingCartItem = (props: {
  id: number;
  title: string;
  completed: boolean;
  sortNr: number;
  onStatusChange: () => void;
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(props.title);
  const [error, setError] = useState(false);

  const save = () => {
    if (!name || !name.length) {
      setError(true);
      return;
    }

    dispatch(
      updateShoppingListItem({
        id: props.id,
        name,
        completed: props.completed,
      }),
    );

    setError(false);
    setEditMode(false);
  };

  const onSave = (e: React.FormEvent) => {
    e.preventDefault();
    save();
  };

  const onClose = () => {
    setEditMode(false);
    setError(false);
    setName(props.title);
  };

  const onDelete = () => {
    dispatch(deleteShoppingListItem(props.id));
  };

  return (
    <li
      className={itemClass}
      style={{
        ...horizontalStyle,
        zIndex: 20,
        justifyContent: 'flex-start',
        lineHeight: 1,
      }}
      onClick={() => {
        if (!editMode) {
          setEditMode(true);
        }
      }}
    >
      <FormCheckbox
        size={16}
        checked={props.completed}
        onChange={props.onStatusChange}
      />
      <div
        style={{
          ...flexHorizontalStyle,
          alignItems: 'center',
          marginLeft: 10,
          userSelect: 'none',
        }}
      >
        {editMode ? (
          <form
            style={{ ...flexHorizontalStyle, alignItems: 'center' }}
            onSubmit={onSave}
          >
            <FormInput
              value={name}
              onChange={e => setName(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Escape') {
                  onClose();
                }
              }}
              autoFocus
              style={{
                ...(error && {
                  border: '1px solid #eb9694',
                }),
              }}
            />
            <a
              onClick={() => save()}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              <span className="fa fa-check" />
            </a>
            <a onClick={onDelete} style={{ color: '#eb9694' }}>
              <span className="fa fa-trash" />
            </a>
          </form>
        ) : (
          props.title
        )}
      </div>
    </li>
  );
};

const itemClass = css({
  padding: 10,
  color: '#888',
  '&:hover': {
    backgroundColor: '#f6f7f4',
    color: brandColor,
  },
});

export { ShoppingCartItem };
