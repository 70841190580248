import React, { useState } from 'react';
import {
  centerStyle,
  flexHorizontalStyle,
  horizontalStyle,
} from '../../styles';
import { FormInput } from '../Form/FormInput';
import { Spinner } from '../Spinner/Spinner';
import { FormButton } from '../Form/FormButton';
import { updatePlanName } from '../../store/PlanStore';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../store';

const HeaderPlanName = (props: {}) => {
  const dispatch = useDispatch();
  const planData = useSelector((state: IRootState) => state.plan);
  const { readOnly } = planData;
  const [formName, setFormName] = useState(planData.name);
  const [savingName, setSavingName] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const onEditClick = () => {
    if (!readOnly && !editMode) {
      setEditMode(true);
      setFormName(planData.name);
    }
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formName) {
      setEditMode(false);
      return;
    }

    setSavingName(true);
    await dispatch(updatePlanName(formName));
    setSavingName(false);
    setEditMode(false);
  };

  const nameContainerStyle = {
    ...flexHorizontalStyle,
    alignItems: 'center',
    fontSize: 18,
    color: '#AAA',
  };

  return (
    <div style={nameContainerStyle} onClick={onEditClick}>
      {readOnly && (
        <div
          style={{
            ...horizontalStyle,
            ...centerStyle,
            fontWeight: 'bold',
            marginRight: 10,
            fontSize: 16,
          }}
        >
          <span className="fa fa-lock" />
        </div>
      )}
      {editMode ? (
        <form onSubmit={onSubmit} style={{ ...flexHorizontalStyle }}>
          <FormInput
            value={formName}
            onChange={e => setFormName(e.target.value)}
          />
          {savingName ? (
            <Spinner />
          ) : (
            <FormButton
              type="submit"
              title="Saglabāt"
              style={{ marginLeft: 10 }}
            />
          )}
        </form>
      ) : (
        planData.name
      )}
    </div>
  );
};

export { HeaderPlanName };
