import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ReactGA from 'react-ga';
import { IRootGetState, IRootState } from './index';
import { AppDispatch } from './store';
import axios from 'axios';
import {
  addToViewedPlanHistory,
  getLatestViewedPlan,
} from '../helpers/planHistoryHelper';
import { generateNewPlanName } from '../helpers/planNameHelper';
import { history } from '../history';
import { apiBaseUrl } from '../config';
import { getShoppingList } from './ShoppingListStore';

type IPlanStore = {
  id: number;
  name: string;
  accessToken: string;
  shareToken: string;
  readOnly: false;
  notes: string;
  unsavedNotes: boolean;
  items: IPlanItem[];
};

export type IPlanItem = {
  id: number;
  created: string;
  updated: string;
  weekDay: number;
  categoryId: number;
  categoryCustomName?: string;
  name: string;
  color?: string;
  moreInfo?: string;
  sortNr: number;
};

type IPlanNewItemPayload = {
  weekDay: number;
  categoryId: number;
  name: string;
  moreInfo?: string;
  categoryCustomName?: string;
  color?: string;
};

type IPlanUpdateItemPayload = IPlanNewItemPayload & { id: number };

export type IPlanMoveItemPayload = {
  newWeekDay: number;
  itemId: number;
  newIndex: number;
};

const initialState: IPlanStore = {
  id: 0,
  name: '',
  accessToken: '',
  shareToken: '',
  readOnly: false,
  notes: '',
  unsavedNotes: false,
  items: [],
};

const PlanStore = createSlice({
  name: 'PlanStore',
  initialState,
  reducers: {
    addItem(state, action: PayloadAction<IPlanItem>) {
      state.items.push(action.payload);
    },

    updateItem(state, action: PayloadAction<IPlanItem>) {
      state.items = state.items.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
    },

    updateItems(state, action: PayloadAction<IPlanItem[]>) {
      state.items = action.payload;
    },

    deleteItem(state, action: PayloadAction<number>) {
      state.items = state.items.filter(item => item.id !== action.payload);
    },

    updatePlanData(state, action: PayloadAction<IPlanStore>) {
      return action.payload;
    },

    updatedUnsavedNotesStatus(state, action: PayloadAction<boolean>) {
      state.unsavedNotes = action.payload;
    },
  },
});

const storeActions = PlanStore.actions;
const { updatedUnsavedNotesStatus } = storeActions;

export { updatedUnsavedNotesStatus };

export const createNewPlan = ({
  name,
  duplicatePlan,
}: {
  name: string;
  duplicatePlan?: boolean;
}) => async (dispatch: AppDispatch, getState: IRootGetState) => {
  try {
    const payload = duplicatePlan
      ? {
          name,
          duplicatePlan,
          originalId: getState().plan.id,
          originalShareToken: getState().plan.shareToken,
        }
      : { name };

    const newPlan = await axios.post<IPlanStore>(
      `${apiBaseUrl}/plan/`,
      payload,
    );

    const { accessToken, id, readOnly } = newPlan.data;
    dispatch(storeActions.updatePlanData(newPlan.data));
    addToViewedPlanHistory({ id, token: accessToken, name, readOnly });
    history.push(`/${newPlan.data.id}/${newPlan.data.accessToken}`);

    await dispatch(getShoppingList());

    ReactGA.pageview(window.location.pathname + window.location.search);

    return newPlan.data;
  } catch (err) {}
};

export const loadPlan = (params: { id: number; token: string }) => async (
  dispatch: AppDispatch,
) => {
  try {
    // If no url params
    if (!params.id || !params.token) {
      const latestViewedPlan = getLatestViewedPlan();

      // Create new plan
      if (!latestViewedPlan) {
        const newPlan = await axios.post<IPlanStore>(`${apiBaseUrl}/plan/`, {
          name: generateNewPlanName(),
        });
        const { accessToken, id, readOnly, name } = newPlan.data;
        dispatch(storeActions.updatePlanData(newPlan.data));
        addToViewedPlanHistory({ id, token: accessToken, name, readOnly });
        return newPlan.data;
      }

      const res = await axios.get<IPlanStore>(
        `${apiBaseUrl}/plan/${latestViewedPlan.id}?token=${latestViewedPlan.token}`,
      );

      addToViewedPlanHistory({
        id: res.data.id,
        token: res.data.readOnly ? res.data.shareToken : res.data.accessToken,
        readOnly: res.data.readOnly,
        name: res.data.name,
      });

      dispatch(storeActions.updatePlanData(res.data));

      await dispatch(getShoppingList());

      ReactGA.pageview(window.location.pathname + window.location.search);

      return res.data;
    } else {
      const res = await axios.get<IPlanStore>(
        `${apiBaseUrl}/plan/${params.id}?token=${params.token}`,
      );
      dispatch(storeActions.updatePlanData(res.data));
      addToViewedPlanHistory({
        id: res.data.id,
        token: res.data.readOnly ? res.data.shareToken : res.data.accessToken,
        readOnly: res.data.readOnly,
        name: res.data.name,
      });

      await dispatch(getShoppingList());

      ReactGA.pageview(window.location.pathname + window.location.search);

      return res.data;
    }
  } catch (err) {}
};

export const updatePlanName = (name: string) => async (
  dispatch: AppDispatch,
  getState: IRootGetState,
) => {
  try {
    await axios.put(`${apiBaseUrl}/plan/${getState().plan.id}`, {
      token: getState().plan.accessToken,
      planId: getState().plan.id,
      name,
    });

    dispatch(
      storeActions.updatePlanData({
        ...getState().plan,
        name,
      }),
    );
  } catch (err) {
    console.log(err);
  }
};

export const updatePlanNotes = (notes: string) => async (
  dispatch: AppDispatch,
  getState: IRootGetState,
) => {
  try {
    await axios.put(`${apiBaseUrl}/plan/${getState().plan.id}`, {
      token: getState().plan.accessToken,
      planId: getState().plan.id,
      notes,
    });

    dispatch(
      storeActions.updatePlanData({
        ...getState().plan,
        notes,
      }),
    );
  } catch (err) {
    console.log(err);
  }
};

export const addItem = (item: IPlanNewItemPayload) => async (
  dispatch: AppDispatch,
  getState: IRootGetState,
) => {
  try {
    const weekItems = [...getState().plan.items].filter(
      i => i.weekDay === item.weekDay,
    );
    const sortNr = weekItems.length;
    const newItem = await axios.post<IPlanItem>(`${apiBaseUrl}/planItem/`, {
      ...item,
      token: getState().plan.accessToken,
      planId: getState().plan.id,
      sortNr,
    });

    dispatch(storeActions.addItem(newItem.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateItem = (item: IPlanUpdateItemPayload) => async (
  dispatch: AppDispatch,
  getState: IRootGetState,
) => {
  try {
    const res = await axios.put<IPlanItem>(
      `${apiBaseUrl}/planItem/${item.id}`,
      {
        ...item,
        token: getState().plan.accessToken,
        planId: getState().plan.id,
      },
    );

    dispatch(storeActions.updateItem(res.data));
  } catch (err) {
    console.log(err);
  }
};

export const duplicateItem = (id: number) => async (
  dispatch: AppDispatch,
  getState: IRootGetState,
) => {
  try {
    const items = getState().plan.items;
    const item = items.find(item => item.id === id);

    if (item) {
      const newItem = await axios.post<{ id: number }>(
        `${apiBaseUrl}/planItem/`,
        {
          ...item,
          token: getState().plan.accessToken,
          planId: getState().plan.id,
        },
      );

      dispatch(storeActions.addItem({ ...item, id: newItem.data.id }));
    }
  } catch (err) {}
};

export const deleteItem = (id: number) => async (
  dispatch: AppDispatch,
  getState: IRootGetState,
) => {
  await axios.delete(
    `${apiBaseUrl}/planItem/${id}?token=${getState().plan.accessToken}&planId=${
      getState().plan.id
    }`,
  );
  dispatch(storeActions.deleteItem(id));
};

export const moveItem = (data: IPlanMoveItemPayload) => async (
  dispatch: AppDispatch,
  getState: IRootGetState,
) => {
  const { newWeekDay, itemId, newIndex } = data;

  const items = [...getState().plan.items];

  const itemsBySortNr = items.sort((a, b) => (a.sortNr < b.sortNr ? -1 : 1));
  let sortNr = 0;

  const itemsSorted = itemsBySortNr.map((item: IPlanItem) => {
    if (item.id === itemId) {
      return {
        ...item,
        weekDay: newWeekDay,
        sortNr: newIndex,
      };
    }

    if (item.weekDay === newWeekDay) {
      if (sortNr === newIndex) sortNr++;
      const returnItem = { ...item, sortNr };
      sortNr++;
      return returnItem;
    }

    return item;
  });

  const sortInfoList = itemsSorted
    .filter(item => item.weekDay === newWeekDay)
    .map((item: IPlanItem) => ({
      id: item.id,
      sortNr: item.sortNr,
      weekDay: item.weekDay,
    }));

  dispatch(storeActions.updateItems(itemsSorted));

  await axios.post(`${apiBaseUrl}/planItem/updatePositions/`, {
    list: sortInfoList,
    token: getState().plan.accessToken,
    planId: getState().plan.id,
  });
};

// Selectors
const itemsSelector = (state: IRootState) => state.plan.items;

export const itemsForWeekDaySelector = (weekDay: number) => {
  return createSelector(itemsSelector, items =>
    items
      .filter(item => item.weekDay === weekDay)
      .sort((a, b) => (a.sortNr < b.sortNr ? -1 : 1)),
  );
};

export default PlanStore.reducer;
