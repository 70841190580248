import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderButton } from './HeaderButton';
import { HeaderDropdownItem } from './HeaderDropdownItem';
import { openPopup } from '../../store/PopupStore';
import { uiShowPanel } from '../../store/UIStore';
import { horizontalStyle } from '../../styles';
import { IRootState } from '../../store';
import { shoppingListActiveItemsCountSelector } from '../../store/ShoppingListStore';
import { LayoutContext } from '../../layout';

const HeaderButtonSection = () => {
  const dispatch = useDispatch();
  const layoutMode = useContext(LayoutContext);
  const showPanel = useSelector((state: IRootState) => state.ui.showPanel);
  const planData = useSelector((state: IRootState) => state.plan);
  const readOnly = useSelector((state: IRootState) => state.plan.readOnly);
  const shoppingListActiveItemsCount = useSelector(
    shoppingListActiveItemsCountSelector,
  );

  const buttonContainerStyle = {
    ...horizontalStyle,
  };

  if (layoutMode === 'mobile') {
    return (
      <div style={buttonContainerStyle}>
        {!readOnly && (
          <HeaderButton
            icon="shopping-cart"
            helpTitle="Iepirkumu saraksts"
            badgeCount={shoppingListActiveItemsCount}
            onClick={() =>
              dispatch(
                uiShowPanel(
                  showPanel !== 'shoppingCart' ? 'shoppingCart' : null,
                ),
              )
            }
            active={showPanel === 'shoppingCart'}
          />
        )}
        {!readOnly && (
          <HeaderButton
            icon="book"
            helpTitle="Piezīmes"
            badgeIcon={planData.unsavedNotes ? 'refresh' : null}
            onClick={() =>
              dispatch(uiShowPanel(showPanel !== 'notes' ? 'notes' : null))
            }
            active={showPanel === 'notes'}
          />
        )}
        <HeaderButton
          icon="ellipsis-v"
          helpTitle="Izvēlne"
          onClick={() =>
            dispatch(
              uiShowPanel(showPanel !== 'menuPanel' ? 'menuPanel' : null),
            )
          }
          active={showPanel === 'menuPanel'}
        />
      </div>
    );
  }

  return (
    <div style={buttonContainerStyle}>
      <HeaderButton
        icon="plus-circle"
        dropdownEnabled
        helpTitle="Jauns plāns vai kopēt plānu"
      >
        <HeaderDropdownItem
          text="Jauns plāns"
          icon="file-o"
          onClick={() =>
            dispatch(openPopup('newPlan', { duplicatePlan: false }))
          }
        />
        <HeaderDropdownItem
          text="Kopēt plānu"
          icon="copy"
          onClick={() =>
            dispatch(openPopup('newPlan', { duplicatePlan: true }))
          }
        />
      </HeaderButton>

      {!readOnly && (
        <HeaderButton
          helpTitle="Dalīties"
          icon="share"
          onClick={() => dispatch(openPopup('sharePlan', {}))}
        />
      )}

      {!readOnly && (
        <HeaderButton
          icon="shopping-cart"
          helpTitle="Iepirkumu saraksts"
          badgeCount={shoppingListActiveItemsCount}
          onClick={() =>
            dispatch(
              uiShowPanel(showPanel !== 'shoppingCart' ? 'shoppingCart' : null),
            )
          }
          active={showPanel === 'shoppingCart'}
        />
      )}

      {!readOnly && (
        <HeaderButton
          icon="book"
          helpTitle="Piezīmes"
          badgeIcon={planData.unsavedNotes ? 'refresh' : null}
          onClick={() =>
            dispatch(uiShowPanel(showPanel !== 'notes' ? 'notes' : null))
          }
          active={showPanel === 'notes'}
        />
      )}

      <HeaderButton
        icon="folder"
        helpTitle="Skatīto plānu vēsture"
        onClick={() =>
          dispatch(uiShowPanel(showPanel !== 'history' ? 'history' : null))
        }
        active={showPanel === 'history'}
      />

      <HeaderButton icon="ellipsis-v" dropdownEnabled helpTitle="Izvēlne">
        <HeaderDropdownItem
          text="Blogs"
          onClick={() => window.open('https://foodprep.lv')}
        />
        <HeaderDropdownItem
          text="Privātums"
          onClick={() => dispatch(openPopup('privacyInfo', {}))}
        />
      </HeaderButton>
    </div>
  );
};

export { HeaderButtonSection };
