import React from 'react';
import { useDispatch } from 'react-redux';
import { PopupHeader } from '../Popups/PopupHeader';
import { closePopup } from '../../store/PopupStore';
import { PopupContent } from '../Popups/PopupContent';

const BetaInfo = () => {
  const dispatch = useDispatch();

  return (
    <>
      <PopupHeader
        title="BETA"
        onClose={() => dispatch(closePopup('betaInfo'))}
      />
      <PopupContent>
        <p>Hej, esi sveicināts Foodprep ēdienreižu e-plānotājā!</p>
        <p>
          Šī ir BETA versija, kas vēl nav pilnībā pabeigta. Tā šobrīd vēl{' '}
          <b>nav optimizēta mobilajām ierīcēm un planšetdatoriem</b>.
        </p>

        <p>
          Ja pamani kādas kļūdas, darbības traucējumus, kā arī ja Tev ir
          jautājumi vai ieteikumi, ko Tu vēlētos redzēt Foodprep ēdienreižu
          plānotājā - droši raksti Klintai (
          <a href="mailto:klinta@foodprep.lv">klinta@foodprep.lv</a> vai
          Instagram{' '}
          <a href="https://instagram.com/foodpreplv" target="_blank">
            @foodpreplv
          </a>
          )
        </p>
      </PopupContent>
    </>
  );
};

export { BetaInfo };
