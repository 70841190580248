import React from 'react';

const Spinner = () => {
  return (
    <img
      src="/spinner.svg"
      alt="Ielādējas.."
      style={{ width: 24, height: 24 }}
    />
  );
};

export { Spinner };
